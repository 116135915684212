import mojs from '@mojs/core'

const OPTS = {
  fill: 'none',
  radius: 25,
  strokeWidth: { 50: 0 },
  scale: { 0: 1 },
  angle: { 'rand(-35, -70)': 0 },
  duration: 500,
  left: 0,
  top: 0,
  easing: 'cubic.out',
  isShowEnd: false
}

const circle1 = new mojs.Shape({
  ...OPTS,
  stroke: '#4941A5',
})

document.addEventListener('click', function (e) {
  circle1
    .tune({
      x: e.pageX,
      y: e.pageY
    })
    .replay()
}, true)
